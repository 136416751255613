<template>
  <div id="loading-screen" v-if="showDialog">
    <div id="loading-wrapper">
      <div id="loading-bar"></div>
      <div id="loading-content">
        <slot></slot>
      </div>
      <div v-if="page==='shop'" class="no-result">{{ currentWarning }}</div>
    </div>
  </div>
</template>

<script>
import { ref, onMounted, onUnmounted } from 'vue'
export default {
  setup() {
    const alertList = [
      '해외에서 읽어오기 때문에 시간이 걸립니다.',
      '키워드에 따라서 조회결과가 없을 수 있습니다.',
      '실패시 vinted.fr에서 직접 검색을 해보세요'
    ]
    const currentWarning = ref(alertList[0])
    let warningIndex = 0
    let warningInterval

    const rotateWarning = () => {
      warningIndex = (warningIndex + 1) % alertList.length
      currentWarning.value = alertList[warningIndex]
    }

    onMounted(() => {
      warningInterval = setInterval(rotateWarning, 2000)
    })

    onUnmounted(() => {
      clearInterval(warningInterval)
    })
    return {
      currentWarning
    }
  },
  props: {
    showDialog: {
      type: Boolean,
      required: true
    },
    page:String
  },
  methods: {
    closeDialog() {
      this.$emit('close');
    }
  }
}
</script>

<style scoped>
#loading-screen {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 9999999999999999999;
  display: flex;
  justify-content: center;
  align-items: center;
}

#loading-wrapper {
  width: 400px;
  height: 200px;
  background-color: white;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

#loading-bar {
  width: 80px;
  height: 80px;
  border-radius: 50%;
  border: 10px solid rgba(0, 0, 0, 0.1);
  border-top-color: #FF7F50;
  animation: spin 1s ease-in-out infinite;
  margin-bottom: 20px;
}

#loading-content {
  text-align: center;
}

@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}
</style>