<template>
  <Toast position="top-left" group="tl" />
  <img id="chat-menu-icon" src="../../assets/chat.png" alt="geek9.kr" @click="click({type:'toggle'})">
  <div id="cruzgpt-modal" class="card">
    <div id="chat-wrapper" ref="chatWrapper">
      <div class="message-container" v-for="(content, index) in contentList" :key="index">
        <Chip v-if="content.msg" :class="['message', content.type]" >
          <img :src="content.type === 'question' ? this.api.getCookies('img') : '../../assets/logo_big.jpg'" style="border-radius:50%;object-fit: cover;width:30px;" /> 
          <span v-html="content.msg" />
        </Chip>
        <div v-if="content.msg" :class="['extra-info', content.type]" >{{ this.api.getDateByFormat(content.regDate, 'YYYY.MM.DD.HH:mm:ss') }}</div>
        <ProgressSpinner v-if="content.msg === ''" style="width:25px; height:25px" strokeWidth="8" fill="transparent"
        animationDuration="1s" aria-label="Custom ProgressSpinner" />
      </div>
    </div>
    <br>
    <InputGroup class="w-full md:w-[30rem]" >
      <!-- <InputGroupAddon>
        <SpeedDial :model="menuList" direction="up" :transitionDelay="80" style="position: absolute;margin-bottom:280px;" >
          <template #button="{ toggleCallback }" >
            <button class="speed-dial link" @click="toggleCallback">
              <i class="pi pi-bars" style="color:blue;"></i>
            </button>
          </template>
          <template #item="{ item, toggleCallback }">
            <div @click="toggleCallback" >
              <InputGroupAddon class="input-group">
                <div class="div_center speed-dial-btn"  >
                  <span >
                    <span :class="item.icon" /><br>
                    &nbsp;<span>{{ item.label }} </span>
                  </span>
                </div>
              </InputGroupAddon>
            </div>
          </template>
      </SpeedDial>
    </InputGroupAddon> -->
    <InputText placeholder="입력" v-model="msg" v-on:keyup.enter="click({type:'CHATBOT_ASK'})" />
      <InputGroupAddon>
        <i class="pi pi-send cruzgpt-menu-btn" @click="click({type:'CHATBOT_ASK'})"></i>
      </InputGroupAddon>
    </InputGroup>
  </div>
</template>

<script>
import { ref, nextTick } from 'vue'

export default {
  mounted() {
    this.add({ type: 'answer', msg: '안녕하세요! GEEK9 챗봇입니다!\n베타 버전이라 아직 멍청해서\n답변과 문의에 제한이 있습니다 T.T\nGEEK9에 대해 기본적인 것만 문의 부탁드리고, \n기업고객인 경우에는 문의게시판이나 이메일로 문의 부탁드리겠습니다!', regDate:new Date() })
    this.getActiveModel()
  },
  setup() {
    const contentList = ref([])
    const chatWrapper = ref(null)
    const menuList = ref([
      {
        label: 'menu1',
        icon: 'pi pi-book',
        id: 'menu1',
        command: () => {
        }
      },
      {
        label: 'mennu2',
        icon: 'pi pi-globe',
        id:'direa_url_0001',
        command: () => {
        }
      },
      {
        label: 'menu3',
        icon: 'pi pi-globe',
        id:'menu2',
        command: () => {
        }
      },
      {
        label: 'menu4',
        icon: 'pi pi-database',
        id:'menu3',
        command: () => {
        }
      }
    ])
    const toggle = async () => {      
      const chatModal = document.getElementById('cruzgpt-modal')
      chatModal.style.visibility = chatModal.style.visibility === 'visible' ? 'hidden' : 'visible'
    }
    const scrollToBottom = () => {
      nextTick(() => {
        const chatElement = chatWrapper.value
        chatElement.scrollTop = chatElement.scrollHeight
      })
    }
    return {
      toggle,
      menuList,
      chatWrapper,
      contentList,
      scrollToBottom
    }
  },
  data() {
    return {
      flag:true,
      msg: '',
      selectedModel:''
    }
  },
  methods: {
    async getActiveModel() {
      await this.api.axiosGql('SELECT_NOTICE2', {type:'SELECT_NOTICE2', priority:-1, noticeType: 'chatbot',  useYn:true})
      .then( async (res) => {
        let json = res.data
        if(!(json === undefined || json.length === 0)) {
          this.selectedModel = json[0].TITLE
        }
      })
    },
    progress(param) {
      const flag = param.flag
      if(flag) this.contentList.push({ type: 'answer', msg: ''})
      else this.contentList = this.contentList.filter(item => item.msg !== '')
    },
    add(param) {
      this.contentList.push(param) 
      this.scrollToBottom()
    },
    async click(param) {
      const type = param.type
      if (type === 'CHATBOT_ASK') {
        if(this.flag === true) {
          this.flag = false
          if (this.msg) {
            if(this.msg.length > 50) this.add({ type: 'answer', msg: '문의는 50자 이내로 부탁드립니다 T.T', regDate:new Date() })
            else if(!(this.msg.indexOf('수고하세요') === -1 && this.msg.indexOf('감사합니다') === -1) ) {
              this.add({ type: 'question', msg: this.msg, regDate:new Date() })
              this.progress({flag:true})
              await new Promise((resolve) => setTimeout(resolve, 1000))
              this.progress({flag:false})
              this.add({ type: 'answer', msg: '감사합니다^^', regDate:new Date() }) 
            } else {
              const formData = new FormData()
              formData.append('type', type)
              formData.append('q', this.msg)
              formData.append('regDate', new Date())
              formData.append('userId', this.api.getCookies('id'))
              formData.append('modelId', this.selectedModel)
              
              this.add({ type: 'question', msg: this.msg, regDate:new Date() })
              this.progress({flag:true})
              await this.api.axiosGql(type, formData)
              .then( async (res) => {
                const answer = res.data.answer
                if(answer.indexOf('is off') !== -1 || res.status === 201) {
                  this.restart({idList:this.selectedModel})
                  this.add({ type: 'answer', msg: '다시 시도해주세요', regDate:new Date() })
                } else if(res.status === 200) {
                  this.add({ type: 'answer', msg: answer, regDate:new Date() }) 
                  this.api.axiosGql('INSERT_NOTICE', {type:'INSERT_NOTICE', id:this.api.getCookies('mail'), noticeType:'chatbot-qa', useYn:false, priority:0, title:this.msg, content:this.api.emojiRemover(res.data.origin)})
                } else this.add({ type: 'answer', msg: '다시 시도해주세요', regDate:new Date() })
              })
              .catch( async (err) => {
                console.log(err)
                this.restart([this.selectedModel])
              })
              .finally( () => {
                this.progress({flag:false})
              })
            }  
            this.msg = ''
          } else this.add({ type: 'answer', msg: '질의를 입력해주세요', regDate:new Date() })
          this.flag = true
        }
      } else if (type === 'toggle') this.toggle()
    },
    async restart(param) {
      const idList = param.idList
      const type = 'CHATBOT_RESTART'
      const formData = new FormData()
      formData.append('type', type)
      formData.append('idList', idList)

      await this.api.axiosGql(type, formData)
      .then( async (res) => {
        if(res.status === 200) this.add({ type: 'answer', msg: '다시 시도해주세요.', regDate:new Date() })
        else this.add({ type: 'answer', msg: '잠시 후에 다시 이용해주시기 바랍니다.', regDate:new Date() })
      })
      .catch( async (err) => {
        console.log(err)
        this.add({ type: 'answer', msg: '잠시 후에 다시 이용해주시기 바랍니다.', regDate:new Date() })
      })
    }
  }
}
</script>

<style scoped>
#chat-menu-icon {
  background: transparent;
  width: 50px;
  position: fixed;
  bottom: 60px;
  cursor: pointer;
  right: 100px;
  z-index: 999999;
}
#chat-wrapper {
  background-color: #f7f7f7;
  height: 400px;
  overflow-y: auto;
  padding: 10px;
  box-sizing: border-box;
}
#chat-wrapper::-webkit-scrollbar {
  width: 0px;
  background: transparent;
}
.message-container {
  display: flex;
  flex-direction: column;
  margin: 10px 0;
}
.message {
  max-width: 30vw;
  word-wrap: break-word;
  white-space: pre-wrap;
  padding: 10px;
  border-radius: 8px;
  margin-bottom: 10px;
}
.message.question {
  align-self: flex-end;
  background-color: skyblue;
}
.message.answer {
  align-self: flex-start;
  background-color: yellow;
}
.extra-info {
  color:gray;
  font-size:10px;
}
.extra-info.question {
  align-self: flex-end;
}
.extra-info.answer {
  align-self: flex-start;
}
#cruzgpt-modal {
  visibility: hidden;
  width: 50%;
  min-width: 400px;
  position: fixed;
  bottom: 120px;
  right: 50px;
  z-index: 99999999;
  border:1px solid #ffa07a;
}
.cruzgpt-menu-btn {
  cursor: pointer;
}
.speed-dial {
  background-color: transparent;
  border:none; 
}
.speed-dial-btn {
  padding:5px;
  width:100%;
  cursor: pointer;
}
.input-group {
  width:150px;
  cursor: pointer; 
}
</style>
